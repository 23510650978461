h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0px;
}
html,
body {
  font-size: 16px;
  margin: 0px;
  scroll-behavior: smooth;
  color: var(--font-primary);
  letter-spacing: 0.2px;
  font-family: "Avenir-Regular";
  @media (max-width: 768px) {
    font-size: 13px;
  }
}
.fullscreen-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.7);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
ul {
  list-style: none;
  padding: 0px;
  a {
    text-decoration: none;
  }
}
table {
  width: 100%;
  border-spacing: 0;
}
img {
  max-width: 100%;
}
@keyframes fade {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #dcdcdc;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
}
.calender-popover {
  z-index: 99999;
  position: relative;
  width: 345px;
  &:after {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
  @media(max-width:767px) {
      width: 80%;
      right: 0 !important;
      margin: 0 auto !important;
      &:after {
        display: none;
      }
  }
  .events-wrap {
    width: 100%;
    max-height: 245px;
    overflow: auto;
    background: #fff;
    border-radius: 8px;
    padding: 16px 24px 24px 24px;
    box-shadow: 0px 6px 26px rgba(178, 204, 234, 0.71);
    position: relative;
    &::-webkit-scrollbar {
        width: 5px;
    }
    @media(max-width:767px) {
      padding: 16px;
    }
    .event .date {
      color: var(--fonts-primary);
      font-size: 1.1rem;
      font-weight: bold;
      margin-bottom: 16px;
    }
  }
}
.strength-tooltip {
  width: 300px;
  .MuiTooltip-tooltip {
    background: #fff;
    border-radius: 4px;
    padding: 10px 10px 6px 10px;
    border: 0.2px solid rgb(120 128 147 / 16%);
    box-shadow: 0px 1px 24px rgb(224 224 224 / 46%);
    margin: 0 !important;
    .MuiTooltip-arrow {
      color: #fff;
    }
    h2 {
      font-size: 0.75rem;
      color: var(--fonts-primary);
      font-weight: bold;
      margin-bottom: 5px;
    }
    p {
      font-size: 0.75rem;
      color: var(--fonts-light);
      line-height: 16px;
    }
    .btn-wrap {
      text-align: right;
      margin-top: 5px;
    }
    button {
      font-size: 0.75rem;
      color: var(--fonts-primary);
      font-weight: bold;
      text-transform: capitalize;
      font-weight: normal;
      padding: 0;
      &:hover {
        color: var(--fonts-light);
      }
    }
  }
}
.question-blk {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(65, 75, 95, 0.16);
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 30px;
  .num-blk {
    width: 120px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
    background: #414b5f;
    border-top-left-radius: 4px;
    @media (max-width: 767px) {
      width: 100px;
    }
  }
  .marks {
    font-size: 1rem;
    color: #414b5f;
    font-weight: bold;
    @media (max-width: 767px) {
      width: calc(100% - 130px);
    }
    span {
      color: var(--green);
    }
  }
  .options-blk {
    padding: 20px;
    .mcq-wrap {
      &.MuiFormGroup-root {
        .option {
          .MuiFormControlLabel-root {
            margin: 0;
          }
          .Mui-checked + .MuiTypography-root {
            color: #fff;
          }
          .MuiTypography-root {
            position: relative;
            z-index: 1;
          }
          .MuiRadio-root {
            position: absolute;
            padding: 0;
            opacity: 0;
            left: 0;
            right: 0;
            .MuiSvgIcon-root {
              width: 38px;
              height: 38px;
            }
          }
          .Mui-checked {
            opacity: 1;
          }
        }
      }
    }
    .descriptive {
      display: flex;
      // justify-content: space-between;
      p {
        color: #788093;
        font-size: 1rem;
        line-height: 24px;
      }
      span {
        color: var(--fonts-primary);
        display: block;
        font-size: 1rem;
        margin-right: 24px;
      }
    }
    .custom-canvas {
      width: 100% !important;
      border: solid 1px #e3e3e3;
    }
    .pictorial {
      &.MuiImageList-root {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .MuiImageListItem-root {
        display: flex;
        width: 40%;
        flex-direction: row;
        cursor: pointer;
        margin-top: 50px;
        @media (max-width: 767px) {
          margin-top: 20px;
          width: 46%;
        }
        h2 {
          font-size: 1rem;
          margin: 0 45px;
          color: var(--fonts-primary);
          @media (max-width: 767px) {
            margin: 0 12px 0 0;
          }
        }
        .img-wrap {
          padding: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 100%;
          }
        }
      }
    }
    .option {
      margin-bottom: 20px;
      align-items: center;
      flex-wrap: nowrap;
      &:last-of-type {
        margin-bottom: 0;
      }
      .selected-option {
        position: relative;
        min-width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        margin-right: 20px;
        border-radius: 50%;
        @media (max-width: 767px) {
          margin-right: 10px;
          min-width: 30px;
          height: 30px;
        }
        &.active {
          border: 2px solid #414b5f;
          align-items: center;
        }
        span {
          color: #414b5f;
          font-size: 1rem;
          line-height: normal;
        }
        .correct-selection {
          width: 100%;
          height: 100%;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background-color: #58a785;
          @media (max-width: 767px) {
            margin-right: 10px;
          }
          span {
            color: #ffffff;
          }
        }
        .incorrect-selection {
          width: 100%;
          height: 100%;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background-color: #de0000;
          span {
            color: #ffffff;
          }
        }
      }
      h3 {
        font-size: 1rem;
        color: #788093;
        line-height: normal;
        font-weight: 400;
        &.active {
          color: #414b5f;
        }
      }
    }
  }
}
.custom-drawing-board {
  height: 70vh;
  .ant-layout-content {
    height: auto;
    overflow: hidden;
  }
  .drawing-board-toolbar-container {
    padding: 0;
    border-radius: 4px;
    box-shadow: none;
    justify-content: center;
    label {
      display: none;
    }
    .drawing-board-toolbar-icon {
      background: #f8f8f8;
      margin: 0;
      height: 55px;
      svg {
        fill: #313c53;
        stroke: #313c53;
        stroke-width: 4px;
        width: 24px;
        height: 24px;
      }
    }
  }
}
.student-info {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 24px 16px;
  &.sidebar-blk {
    background: linear-gradient(
      93deg,
      rgba(253, 249, 241, 0.3953) 3.77%,
      rgba(252, 250, 246, 0) 99.25%
    );
    border: 1px solid #f6f7fb;
    border-right: 0;
    margin-left: 20px;
    transition: all ease 0.4s;
    opacity: 0;
    margin-bottom: 45px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
  .MuiAvatar-root {
    background: var(--color-primary);
  }
  .info {
    margin-left: 15px;
    h2 {
      font-size: 0.85rem;
      color: #202020;
      font-weight: 500;
      margin-bottom: 3px;
    }
    h3 {
      font-size: 0.75rem;
      color: var(--font-gray);
      font-weight: 500;
    }
  }
}
.custom-popup {
  .MuiBackdrop-root {
    background: rgba(65, 75, 95, 0.51);
  }
  .MuiDialog-paper {
    margin: 0;
    max-width: 800px;
    width: 800px;
    border-radius: 8px;
    box-shadow: none;
    .popup-head {
      padding: 20px 20px 20px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .MuiTypography-root {
        font-weight: bold;
        font-size: 1.2rem;
        color: var(--fonts-primary);
        padding: 0;
      }
      button {
        color: var(--primary);
        position: initial;
      }
    }
    .MuiDialogContent-root {
      padding-bottom: 50px;
    }
    .img-wrap {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }
    .popup-title {
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--fonts-primary);
      margin-bottom: 10px;
    }
    p {
      font-size: 1rem;
      line-height: 28px;
      font-weight: 500;
      max-width: 500px;
      margin: 0 auto;
      color: var(--fonts-primary);
    }
  }
}
#webpack-dev-server-client-overlay {
  display: none;
}

.activity-listing {
  > h2 {
    font-size: 0.85rem;
    color: var(--fonts-primary);
    margin-bottom: 20px;
    font-weight: bold;
  }
  .exam-subject-filters {
    margin-bottom: 45px;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    button {
      color: #788093;
      margin-right: 15px;
      padding: 3px 10px;
      text-transform: capitalize;
      min-width: initial;
      font-size: 1rem;
      text-wrap: nowrap;
      &.active {
        font-weight: bold;
        color: var(--fonts-primary);
      }
    }
  }
}
.active-subject-filter {
  color: #000000 !important;
}
.dot {
  width: 5px !important;
  height: 5px !important;
  margin-top: 40px !important;
  border-radius: 50% !important;
  position: absolute !important;
  background-color: var(--color-primary);
}
.main-wrapper,
.MuiModal-root {
  .caption {
    font-size: 0.85rem;
    color: var(--fonts-primary);
    margin-bottom: 15px;
    font-weight: bold;
    line-height: normal;
  }
}
// .css-11o2879-MuiDrawer-docked + .main-wrapper ,.css-nws099 + .main-wrapper{
//   margin-left: 72px;
// }
.app-header.sidebarActive ~ .main-wrapper {
  @media (min-width: 1200px) {
    margin-left: 240px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    margin-left: 0;
  }
}
.app-header {
  @media (max-width: 767px) {
    margin: 0 !important;
    width: 100% !important;
    background: #fff !important;
  }
}
.main-wrapper {
  padding-top: 125px;
  width: 100%;
  background: #f7f8fc;
  min-height: 100vh;
  margin-left: 72px;
  @media (max-width: 767px) {
    padding-top: 100px;
    margin-left: 0;
  }
  .filter-btn {
    padding: 8px 18px 8px 40px;
    color: var(--fonts-primary);
    font-size: 0.85rem;
    background: #ffffff;
    border: 0.4px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: 500;
    line-height: normal;
    > .MuiButton-startIcon {
      position: absolute;
      left: 10px;
      margin: 0;
    }
  }
  .btn-filter-type {
    padding: 8px 18px 8px 18px;
    color: var(--fonts-primary);
    font-size: 0.85rem;
    background: #ffffff;
    border: 0.4px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: 500;
  }
  .sliderTypeTwo {
    .MuiTypography-root {
      font-size: 0.75rem;
      color: var(--fonts-primary);
      font-weight: bold;
      margin-bottom: 15px;
    }
    .MuiSlider-root {
      .MuiSlider-thumb {
        width: 15px;
        height: 15px;
        border: solid 4px #fff;
      }
      .MuiSlider-rail {
        color: #dadee5;
        opacity: 1;
      }
      .MuiSlider-track {
        color: #0a75f5;
      }
      .MuiSlider-mark {
        display: none;
      }
      .MuiSlider-markLabel[data-index="0"] {
        left: 5px !important;
      }
      .MuiSlider-markLabel[data-index="1"] {
        right: -5px !important;
        left: initial !important;
      }
      .MuiSlider-markLabel {
        font-weight: 400;
        color: #414b5f;
        font-size: 0.65rem;
      }
    }
  }
  .question-slider-wrap {
    .MuiSlider-root {
      color: transparent;
      .MuiSlider-thumb {
        &:before {
          display: none;
        }
        &:hover {
          box-shadow: none;
        }
      }
      .MuiSlider-markLabel {
        font-size: 0.65rem;
        font-weight: 500;
        color: var(--fonts-primary);
      }
      .MuiSlider-markLabel[data-index="0"] {
        &.MuiSlider-markLabelActive {
          color: #f63c42;
        }
      }
      .MuiSlider-markLabel[data-index="1"] {
        &.MuiSlider-markLabelActive {
          color: #ff9823;
        }
      }
      .MuiSlider-markLabel[data-index="2"] {
        &.MuiSlider-markLabelActive {
          color: #1c8c7d;
        }
      }
      .MuiSlider-markLabel[data-index="3"] {
        &.MuiSlider-markLabelActive {
          color: #117163;
        }
      }
      .MuiSlider-valueLabel {
        display: none;
      }
    }
  }
  .question-type {
    color: var(--fonts-secondary);
    font-size: 1.5rem;
    font-weight: 800;
    margin-top: 20px;
  }
  .question-heading {
    color: var(--fonts-primary);
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.6rem;
  }
  .MuiDivider-root.divider-custom {
    border-color: rgba(199, 224, 253, 0.6);
    width: 100%;
    h2 {
      color: var(--fonts-primary);
      font-size: 1.1rem;
      font-weight: 800;
    }
  }
  .MuiChip-root {
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 0.7rem;
    font-weight: 500;
    text-transform: capitalize;
    border: 0;
    height: auto;
    > span {
      padding: 0;
    }
    &.levelChip {
      background: rgba(34, 196, 97, 0.1);
      color: #22c461;
    }
    &.tagChip {
      background: rgba(10, 117, 245, 0.1);
      color: var(--fonts-primary);
    }
  }
  .sub-heading {
    font-size: 0.75rem;
    color: var(--fonts-primary);
    margin-bottom: 15px;
    font-weight: 500;
    line-height: normal;
  }
}
.btn-primary.MuiButton-root,
.btn-secondary.MuiButton-root {
  width: 135px;
  height: 45px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--fonts-primary);
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: bold;
  transition: all ease 0.3s;
  &:hover {
    background: var(--primary);
  }
}
.btn-secondary.MuiButton-root {
  background: var(--primary);
  &:hover {
    background: var(--fonts-primary);
  }
}
.btn-text.MuiButton-root {
  color: #23417a;
  font-weight: bold;
  font-size: 0.7rem;
  line-height: normal;
  .MuiButton-startIcon {
    margin: 0;
    > span {
      font-size: 0.8rem;
    }
  }
}
.css-1xq3l18 {
  margin: 0;
}
.circular-progress-bar {
  width: 50px;
  .CircularProgressbar-text {
    fill: var(--fonts-primary);
    font-weight: 800;
    font-size: 1.2rem;
  }
  .CircularProgressbar-path {
    stroke: var(--color-primary);
  }
  .CircularProgressbar-trail {
    stroke: #f8f8f8;
  }
}
.quiz-exams .custom-cards-wrap .custom-cards {
  width: calc(50% - 20px);
}
.custom-cards-wrap.assignment .custom-cards .card-info .info:last-of-type {
  //width: 100%;
}
.custom-cards-wrap {
  display: flex;
  flex-wrap: wrap;
  .custom-cards {
    background: #ffffff;
    box-shadow: 0px 8px 24px 1px #e9ebff;
    border-radius: 10px;
    padding: 24px 24px 0 24px;
    width: calc(33.33% - 20px);
    margin: 0 20px 30px 0;
    cursor: pointer;
    cursor: pointer;
    @media (max-width: 768px) {
      width: calc(50% - 20px);
    }
    @media (max-width: 767px) {
      padding: 16px 16px 0 16px;
      width: 100% !important;
      margin: 0 0 20px 0;
    }
    &.active {
      background: #0a1c27;
      .top-blk {
        h2,
        h3 {
          color: #fff;
        }
        .hr-blk {
          background: var(--primary);
          width: 85px;
          h4 {
            color: #fff;
            text-transform: capitalize;
          }
        }
      }
      .card-info .info {
        h4 {
          color: #fff;
          .MuiAvatar-root {
            border-color: #fff;
          }
        }
      }
    }
    &.disabled {
      background: #e8e8e8;
      cursor: not-allowed;
    }
    h2 {
      color: var(--fonts-primary);
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 20px;
      text-transform: capitalize;
      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
    h3 {
      color: var(--fonts-secondary);
      font-weight: 400;
      font-size: 0.85rem;
      text-transform: capitalize;
      span {
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .MuiCardContent-root {
      padding: 0 !important;
    }
    .top-icons {
      display: flex;
      justify-content: space-between;
      @media (min-width: 1024px) {
        min-height: 44px;
      }
      .progress-blk {
        background: rgba(193, 193, 193, 0.09);
        border-radius: 3px;
        height: 44px;
        width: 44px;
        line-height: normal;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        h2 {
          font-size: 0.75rem;
          font-weight: bold;
          color: #414b5f;
          margin-bottom: 0;
        }
        h3 {
          font-size: 0.85rem;
          font-weight: normal;
          color: #167c6e;
          margin-bottom: 0;
        }
        svg[data-testid="TimelineIcon"] {
          width: 9px;
        }
      }
    }
    .card-icon {
      font-size: 2rem;
      color: var(--fonts-primary);
      margin-bottom: 12px;
      display: block;
    }
    .top-blk {
      h2 {
        color: var(--fonts-primary);
        font-weight: 600;
        font-size: 1.25rem;
        text-transform: capitalize;
        margin-bottom: 10px;
      }
      h3 {
        color: var(--fonts-primary);
        font-size: 0.75rem;
        text-transform: capitalize;
      }
      h4 {
        color: var(--fonts-primary);
        font-size: 0.85rem;
        font-weight: 500;
      }
      .tags {
        border-radius: 1px;
        display: flex;
        align-items: center;
        height: 20px;
        padding: 0 14px;
        &.new {
          background: rgba(28, 140, 125, 0.1);
          h4 {
            color: #1c8c7d;
          }
        }
        &.recomended {
          background: rgba(255, 120, 0, 0.1);
          h4 {
            color: #ff7800;
          }
        }
        &.strength {
          background: rgba(120, 128, 147, 0.1);
          h4 {
            color: #788093;
          }
        }
        h4 {
          font-size: 0.65rem;
          line-height: normal;
        }
      }
      .hr-blk {
        background: rgba(120, 128, 147, 0.1);
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 75px;
        padding: 0 8px;
        h4 {
          color: #788093;
          font-size: 0.8rem;
          line-height: normal;
          margin-top: 2px;
        }
      }
    }
    .lectures-blk {
      font-size: 0.75rem;
      color: var(--fonts-secondary);
      font-weight: 500;
      margin-bottom: 20px;
    }
    .card-info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      > h3 {
        width: 50%;
        margin-bottom: 16px;
        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
        &:nth-child(even) {
          text-align: right;
        }
      }
      .info {
        width: 50%;
        margin-bottom: 15px;
        &:nth-child(even) {
          display: flex;
          justify-content: flex-end;
        }
        h4 {
          color: var(--fonts-secondary);
          font-weight: 400;
          font-size: 0.85rem;
          text-transform: capitalize;
          span {
            margin-right: 5px;
            display: inline-block;
            vertical-align: middle;
          }
          .MuiAvatar-root {
            width: 22px;
            height: 22px;
            border: 1px solid rgba(144, 176, 215, 0.81);
          }
        }
      }
    }
    .btm-blk {
      h3 span {
        color: var(--fonts-primary);
        display: block;
        font-weight: 800;
      }
    }
  }
}
.tabs-primary {
  border-bottom: solid 1px #b9bcc133;
  .MuiButtonBase-root {
    color: var(--fonts-primary) !important;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    transition: all ease 0.3s;
    &.Mui-selected {
      font-weight: 800;
    }
  }
  .MuiTabs-indicator {
    background-color: var(--fonts-primary);
  }
}
.dropdownMenu .MuiPaper-root {
  padding: 15px 24px;
  box-shadow: 0px 0px 26px rgb(189 189 189 / 25%);
  border-radius: 4px;
  .dropdownMenuTitle {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--fonts-primary);
    margin-bottom: 20px;
  }
  .MuiFormGroup-root {
    label {
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 25px;
      }
      .MuiTypography-root {
        font-size: 0.75rem;
        color: var(--fonts-primary);
        font-weight: 500;
        letter-spacing: 0.2px;
        position: relative;
        top: 1px;
      }
      .MuiCheckbox-root {
        padding: 5px;
        svg {
          fill: var(--fonts-primary);
        }
      }
    }
  }
}
.SidebarDialog {
  .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.24);
  }
  .MuiDialog-container {
    justify-content: flex-end;
    .MuiPaper-root {
      width: 525px;
      box-shadow: none;
      .MuiDialogTitle-root {
        height: 85px;
        line-height: 85px;
        padding: 0 0 0 24px;
        background: #f4f9ff;
        color: var(--fonts-primary);
        font-weight: bold;
        font-size: 1.1rem;
        position: relative;
        .MuiIconButton-root {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          color: var(--fonts-primary);
        }
      }
      .MuiDialogContent-root {
        padding: 24px;
      }
    }
  }
}
.custom-input-wrap {
  &.select-box {
    .MuiInputBase-root {
      width: 100%;
      .MuiSvgIcon-root {
        padding-right: 5px;
      }
      .MuiSelect-select {
        background: #fefeff;
        border-radius: 4px;
        font-size: 0.75rem;
        color: var(--fonts-primary);
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #e9e9e9 !important;
      }
    }
  }
  &.bg-light {
    .MuiFormControl-root {
      .MuiInputBase-root {
        background: #fefeff;
      }
    }
  }
  &.custom-date-picker {
    .MuiFormControl-root {
      .MuiInputBase-root {
        .MuiButtonBase-root {
          color: var(--fonts-primary);
          .MuiSvgIcon-root {
            font-size: 0.6rem;
          }
        }
        input {
          &::placeholder {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .MuiCheckbox-root {
    &.Mui-checked {
      .MuiSvgIcon-root {
        color: #2ec383;
      }
    }
    .MuiSvgIcon-root {
      color: #e9e9e9;
      font-size: 1.3rem;
    }
  }
  .MuiTypography-root {
    color: var(--fonts-primary);
    line-height: 2rem;
    font-size: 0.75rem;
    font-weight: bold;
  }
  .MuiFormControl-root {
    width: 100%;
    .MuiInputBase-root {
      height: 40px;
      background: #f6f7f9;
      fieldset {
        border: 1px solid #e9e9e9;
      }
      input {
        padding: 0 0 0 10px;
        font-size: 0.75rem;
        line-height: normal;
        &::placeholder {
          color: rgba(36, 53, 72, 0.4);
        }
      }
    }
  }
  .icon-send {
    color: #0a75f5;
    transform: rotate(-45deg);
    font-size: 1rem;
  }
}
.custom-grid {
  .ag-header-cell {
    padding: 0 12px 0 12px;
    .ag-cell-label-container {
      .ag-header-cell-label {
        .ag-header-cell-text {
          color: #00072b;
          font-size: 0.85rem;
          font-weight: 500;
        }
      }
    }
  }
  .ag-row {
    border: 0;
    .ag-cell {
      color: #00072b;
      font-size: 0.85rem;
      font-weight: 400;
      box-shadow: inset 0px -1px 0px #dfe2e9;
      padding: 0 12px;
    }
  }
  .ag-paging-panel {
    border: 0;
    background: #e6f1ff;
    height: 45px;
    color: #00072b;
  }
}
.custom-border {
  height: 1px;
  margin-bottom: 30px;
  width: 100%;
}
.general-btm-blk {
  padding: 30px 24px;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(6px);
  position: sticky;
  bottom: 0;
  width: 100%;
  border-top: solid 1px rgba(132, 134, 137, 0.2);
}
.custom-editor {
  &.rdw-editor-wrapper {
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    .rdw-editor-toolbar {
      padding: 0;
      border: 0;
      border-bottom: 1px solid #f2f2f2;
      .rdw-option-wrapper,
      .rdw-dropdown-wrapper {
        box-shadow: none !important;
        border: 0;
        border-radius: 0;
        padding: 0;
        margin: 12px;
      }
    }
    .rdw-editor-main {
      min-height: 120px;
      .public-DraftStyleDefault-block {
        margin: 10px 15px;
        span[data-text] {
          color: var(--fonts-primary) !important;
        }
      }
    }
  }
}
.ai-area-doc-viewer {
  width: 97%;
  height: calc(100vh - 415px);
}
