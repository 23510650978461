header {
  border-bottom: 1px solid rgba(231, 240, 250, 0.5);
  .MuiIconButton-root {
    color: #5d5d5d;
  }
  .MuiToolbar-root {
    min-height: 80px;
  }
  .MuiBox-root {
    button {
      color: black;
    }
    .MuiButton-root {
      background: rgba(247, 252, 254, 0.3);
      height: 64px;
      border-left: 1px solid rgba(231, 240, 250, 0.5);
      > .icon-grade {
        display: block;
        margin: -2px 8px 0 0;
        font-size: 1.3rem;
      }
    }
    &.right-blk {
      display: flex;
      .MuiIconButton-root {
        background: #ffffff;
        border: 1px solid rgba(199, 224, 253, 0.5);
        box-sizing: border-box;
        border-radius: 2px;
        padding: 0;
        height: 34px;
        width: 38px;
        margin: 0;
        span {
          font-size: 1.2rem;
          display: flex;
          color: var(--fonts-primary);
        }
      }
      .MuiIconButton-root:nth-child(2) {
        margin-right: 15px;
        @media (max-width: 767px) {
          margin-right: 5px;
        }
      }
    }
  }
  .blessing-blk {
    .cloud-icon {
      margin: 0 16px 0 50px;
    }
    h2 {
      font-size: 1rem;
      color: var(--fonts-primary);
      font-weight: bold;
      span {
        font-weight: 300;
      }
    }
  }
  .search-wrap {
    margin-right: 40px;
    transition: all ease 0.4s;
    &.active {
      width: calc(100% - 24px);
      position: absolute;
      left: 12px;
      margin: 0;
      z-index: 99;
      background: #fff;
      .icon-close {
        display: block;
      }
      input {
        width: calc(100% - 50px);
      }
    }
    @media (max-width: 767px) {
      margin-right: 5px;
      width: 36px;
      position: absolute;
      right: 96px;
    }
    .icon-close {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      fill: #979797;
      transition: all ease 0.3s;
      display: none;
    }
    .MuiFormControl-root {
      width: 100%;
    }
    .MuiInputAdornment-root {
      min-width: 20px;
      height: 20px;
      margin: 0;
    }
    .MuiInputBase-root {
      background: rgba(255, 255, 255, 0.63);
      border-radius: 2px;
      padding: 0 12px;
      @media (max-width: 767px) {
        padding: 0 8px;
      }
      .MuiSvgIcon-root {
        width: 20px;
        fill: #979797;
      }
      input {
        height: 34px;
        padding: 0;
        font-size: 0.75rem;
        width: 300px;
        transition: width 0.5s ease;
        &::placeholder {
          color: #979797;
        }
      }
      fieldset {
        border: 1px solid rgba(199, 224, 253, 0.5);
      }
    }
  }
}
